<template>
  <div class="page-header">
    <h3>Create New Widget</h3>
  </div>
  <WidgetForm v-if="profile" :widgetObj="widget" :isLoading="isLoading" @form-submit="handleSubmit" />
  <div v-else class="inner-container not-found">
    <h2>Uh-oh... Something Went Wrong. If problem persists, get in touch with us.</h2>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import { Toast } from '../composables/getGlobalVariables'
import getUser from '../composables/getUser'
import getCollection from '../composables/getCollection'
import useFetch from '../composables/useFetch'
import WidgetForm from '../components/Widget/WidgetForm.vue'

export default {
  components: { WidgetForm },
  setup() {
    const router = useRouter()
    const { user } = getUser()
    const { profile, widgets } = getCollection()
    const { postData, error, isLoading } = useFetch()
    
    const widget = {
      details: {
        name: '',
        domain: '',
        createdAt: null,
        userId: user.value.uid,
        active: true,
        report: null,
        access: [user.value.uid],
        sharedUsers: {}
      },
      emojis: {
        0: {
          emoji: 'emoji-263',
          emojiURL: 'https://app.emojise.com/assets/emojis/emoji-263.png',
          type: 'positive',
          votes: 0,
          followUps: 0,
          statusCount: {
            new: 0,
            working: 0,
            completed: 0
          }
        },
        1: {
          emoji: 'emoji-120',
          emojiURL: 'https://app.emojise.com/assets/emojis/emoji-120.png',
          type: 'neutral',
          votes: 0,
          followUps: 0,
          statusCount: {
            new: 0,
            working: 0,
            completed: 0
          }
        },
        2: {
          emoji: 'emoji-102',
          emojiURL: 'https://app.emojise.com/assets/emojis/emoji-102.png',
          type: 'negative',
          votes: 0,
          followUps: 0,
          statusCount: {
            new: 0,
            working: 0,
            completed: 0
          }
        }
      },
      settings: {
        number: 3,
        embed: 'float',
        position: 'left',
        mainColour: '#ffa1a1',
        textColour: '#ffffff',
        bgColour: '#fff0f0',
        show: 'automatic',
        delay: '30',
        exclPages: [],
        screenshot: false,
        votes: false,
        branding: true,
        reversed: false,
        multiple: false
      },
      texts: {
        button: 'Feedback',
        title: 'What do you think about our website?',
        subtitle: 'We\'d love to get your feedback',
        submit: 'Send Feedback',
        thanks: 'Thanks for your feedback 🙏',
        posQn: 'Please describe your experience...',
        negQn: 'Please describe what went wrong...',
        votes: 'Or vote for an existing response below...',
        screenshot: 'Select an element from the page'
      },
      stats: {
        totalVotes: 0,
        followUps: 0,
        statusCount: {
          new: 0,
          working: 0,
          completed: 0
        }
      }
    }
    
    const handleSubmit = async (e, widget) => {
      if (profile.value.billing.plan !== 'free-plan' && profile.value.billing.status !== 'active') {
        Toast.fire({
          icon: 'error',
          title: 'Hey there...',
          text: 'Your subscription is currently inactive. Please renew it or downgrade your plan.',
        })

        return
      }

      if (profile.value.billing.plan === 'free-plan' && widgets.value.filter(el => el.details.userId == profile.value.id).length >= 2) { 
        Toast.fire({
          icon: 'error',
          title: 'Hey there...',
          text: 'Free plans can have a maximum of 2 created widgets. Please delete one of your widgets, or upgrade your subscription plan.',
        })

        return
      }
      
      let value = (widget.settings.reversed) ? 1 : Object.keys(widget.emojis).length
    
      Object.values(widget.emojis).forEach((el, index) => {
        widget.emojis[index].value = value
        
        if (widget.settings.reversed) {
          value++
        } else {
          value--
        }
      })
      
      const { widgetId } = await postData(`/widgets`, widget)

      if (!error.value) {
        Toast.fire({
          icon: 'success',
          title: 'Awesome!',
          text: 'Widget created successfully'
        })
        
        window.scrollTo({top: 0})
        router.push({ name: 'Embed', params: { id: widgetId }})
      } else {
        Toast.fire({
          icon: 'error',
          title: 'Uh-oh...',
          text: `Error: ${error.value}`
        })
      }
    }

    return { profile, widgets, widget, handleSubmit, isLoading }
  }
}
</script>